import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import style from "./style";

const TitleSubtitle = ({ classes, title, subtitle, showClass }) => {

  subtitle = subtitle || ""

  return (
    <Grid container className={showClass ? classes.whiteTxt : classes.blueTxt}>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justify="center"
        direction="column"
      >
        <Typography variant="h1" component="h2" className="title">
          {title}
        </Typography>
        <div className="subtitle">{subtitle}</div>
      </Grid>
    </Grid>
  );
};

TitleSubtitle.defaultProps = {
  showClass: false,
};

TitleSubtitle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  showClass: PropTypes.bool,
};
export default withStyles(style)(TitleSubtitle);

const styles = (theme) => ({
  whiteTxt: {
    "& .title": {
      color: "#fff",
      textTransform: "uppercase",
      textAlign: "center",
    },
    "& .subtitle": {
      color: "#fff",
      textAlign: "center",
      fontWeight: 700,
      marginTop: theme.spacing(3),
      letterSpacing: 1.1,
      fontSize: 18,
      lineHeight: 1.5,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        fontSize: 17,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
        fontSize: 16,
      },
      "& p": {
        color: "#4c4c78",
        textAlign: "center",
        letterSpacing: 1.1,
        fontWeight: 700,
        marginTop: theme.spacing(3),
        fontSize: 18,
        lineHeight: 1.5,
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(2),
          fontSize: 17,
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(1),
          fontSize: 16,
        },
        '& strong': {
          fontWeight: 900,
        }
      },
    },
  },
  blueTxt: {
    "& .title": {
      color: "#4c4c78",
      textTransform: "uppercase",
      textAlign: "center",
    },
    "& .subtitle": {
      color: "#4c4c78",
      textAlign: "center",
      letterSpacing: 1.1,
      fontWeight: 700,
      marginTop: theme.spacing(3),
      fontSize: 18,
      lineHeight: 1.5,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        fontSize: 17,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
        fontSize: 16,
      },
      "& p": {
        color: "#4c4c78",
        textAlign: "center",
        letterSpacing: 1.1,
        fontWeight: 700,
        marginTop: theme.spacing(3),
        fontSize: 18,
        lineHeight: 1.5,
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(2),
          fontSize: 17,
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(1),
          fontSize: 16,
        },
        '& strong': {
          fontWeight: 900,
        }
      },
    },
  },
});
export default styles;

const styles = (theme) => ({
  speaker: {
    boxShadow: "0 22px 57px 0 rgba(37, 44, 80, 0.5)",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&>a": {
      display: "flex",
      flexDirection: "column",
      height: '100%',
      textDecoration:'none',
    },
    "& .itemImage": {
      verticalAlign: "bottom",
    },
    "& .speakerInfo": {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
      },
      flex: 1,
      "& .tag": {
        display: "inline-block",
        borderRadius: 2,
        width: "fit-content",
        backgroundColor: "#4a92cf",
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        fontSize: 12,
        color: "#ffffff",
        fontWeight: 700,
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")]: {
          fontSize: 11,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 10,
        },
        "& a": {
          color: '#fff',
        }
      },
      "& .name": {
        color: "#4c4c78",
        lineHeight: 1.19,
        fontWeight: 700,
        flex: 1,
      },
      "& .title": {
        lineHeight: 1.67,
        color: "#4c4c78",
        fontWeight: 500,
        fontFamily: "Raleway,Assistant,sans-serif",
        marginTop: theme.spacing(2),
        display: "block",
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(1),
        },
      },
      "& button": {
        width: "100%",
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
});
export default styles;

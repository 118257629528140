import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import style from "./style";
import CustomLink from "../../components/CustomLink";
import Image from "next/image";
const PersonItem = ({
  classes,
  tag,
  name,
  title,
  bannerImageSrc,
  bannerImageAlt,
  btnLabel,
  showTag,
  showBtn,
  link,
}) => {
  return (
    <div className={classes.speaker}>
      <CustomLink link={link ? link : {}} class="custLinkItem">
        <div style={{ position: 'relative', height: 273, width: '100%' }}>
          <Image objectFit={'cover'} layout='fill' src={bannerImageSrc} alt={bannerImageAlt} className="itemImage" />
        </div>
        <div className="speakerInfo">
          {showTag && <div className="tag">{tag}</div>}
          <Typography variant="h5" className="name">
            {name}
          </Typography>
          <Typography variant="h6" className="title">
            {title}
          </Typography>
          {showBtn && (
            <Button variant="outlined" color="primary">
              {btnLabel}
            </Button>
          )}
        </div>
      </CustomLink>
    </div>
  );
};

PersonItem.defaultProps = {
  tag: "",
  btnLabel: "",
  showTag: false,
  showBtn: false,
};

PersonItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  tag: PropTypes.any, // tag can be html or a string
  name: PropTypes.string,
  title: PropTypes.string,
  bannerImageSrc: PropTypes.string,
  bannerImageAlt: PropTypes.string,
  btnLabel: PropTypes.string,
  showTag: PropTypes.bool,
  showBtn: PropTypes.bool,
};
export default withStyles(style)(PersonItem);
